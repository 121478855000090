import * as React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import {
  CardMedia,
  Slide,
  Typography,
  ImageList,
  ImageListItem,
} from "@material-ui/core";

import Seo from "../components/Seo/Seo";
import { Layout } from "../components/Layout";

import OfficeMeetingImg from "../images/aboutUs-page/office-meeting.jpg";
import MacFamily from "../images/aboutUs-page/macMillan-family.jpg";
import MacFriends from "../images/aboutUs-page/macmillan-friends.jpg";
import MacManager from "../images/aboutUs-page/macmillan-manager-child.jpg";

import { useStyles } from "../pagesStyles/aboutUs.styling";

const About = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About Us",
    },
  ];

  const imageListData = [
    {
      img: MacFamily,
      title: "MacFamily",

      cols: 2,
    },
    {
      img: MacFriends,
      title: "MacFriends",

      cols: 1,
    },
    {
      img: MacManager,
      title: "MacManager",

      cols: 1,
    },
  ];
  return (
    <>
      <Seo title="About Us" />
      <Layout gutter marginTop breadcrumb={breadcrumbs}>
        <section style={{ padding: desktop ? "0 5%" : 0 }}>
          <Grid container direction={desktop ? "row" : "column"} spacing={5}>
            <Grid item xs={desktop ? 6 : 12}>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                <strong>Our Aim...</strong>
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                <strong>
                  Professional Cleaning and Housekeeping Services{" "}
                </strong>
                are a local independent business offering high standards of
                cleaning, housekeeping, gardening, and concierge services,
                providing holistic support in your own home.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                We understand that keeping on top of the chores at home can
                become challenging, whether it is due to being busy working
                full-time or a busy lifestyle and it is becoming harder to keep
                on top of everything. Our team can support you in keeping your
                home as beautiful as you would like it to be.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                Or do you have offices that need regular cleaning to ensure that
                your staff are working in a clean and safe environment. We can
                support you with this too.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                Our team will arrive at a time that best suits you and using our
                Cloud Technology systems, we are able to monitor the length of
                the call agreed by our customers.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                When offering deep cleaning, we want to do our bit for the
                environment and that is why where possible we will use “green”
                cleaning products that are not harmful to the environment. When
                cleaning your home, we will use your products and equipment to
                reduce cross contamination.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                We are currently operating across Solihull and surrounding
                areas.
              </Typography>
              <Typography variant="h5">
                Do not hesitate to get in touch to get a quotation or to arrange
                for any of our services.
              </Typography>
            </Grid>
            <Grid item xs={desktop ? 6 : 12}>
              <Slide direction="right" in timeout={700}>
                <CardMedia
                  style={{ height: "60vh" }}
                  image={OfficeMeetingImg}
                  // alt={altTag}
                  classes={{ root: classes.cardImageL }}
                  component="img"
                />
              </Slide>
            </Grid>
          </Grid>
          <Grid container direction={desktop ? "row" : "column"} spacing={5}>
            <Grid item xs={desktop ? 6 : 12}>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                <strong>Our Community...</strong>
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                <strong>Macmillan Coffee Mornings</strong>
                is just one of the examples of our community engagement, which
                is at the very heart of our identity at PCH.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                PCH Services hosts annual MacMillan Coffee morning which are a
                huge success. We are delighted to announce we raised a grand
                total of £856.00, this last round, which is amazing. All
                proceeds raised have been donated to MacMillan.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                We would like to extend a heartfelt thank you to the Drum and
                Monkey in Dorridge for providing the venue and a never-ending
                supply of tea &amp; coffee. Many of the amazing cakes were
                supplied by Monica’s Bakes a wonderful new bakery located in
                Knowle who donated the entire proceeds raised from the bake
                sale.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                Thank you to all our friends, colleagues and customers who came
                along and joined us on the day and purchased raffle tickets,
                baked buns and took part in the Bingo &amp; tombola. A huge
                thank you to all the local businesses in our community listed
                below who kindly donated the fantastic raffle prizes. Each and
                every one of you contributed to the success of this fund-raising
                event.
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                Thanks to our partners:
              </Typography>
              <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
                Enzo &amp; Co Dorridge, Tescos of Knowle &amp; Dorridge, Costa
                Coffee Dorridge, The Boutique Salon in Knowle, Howelas of
                Knowle, Windridge Pharmacy in Knowle, The Red Lion in Knowle,
                Picadilly of Knowle, Blush Salon Monkspath, Tap and Tandoori,
                Beckkets Farm, Darlings of Chelsea, Achill Spa, Touchwood
                Solihull, J W Towbars, Solihull Physio Pilates
              </Typography>
            </Grid>
            <Grid item xs={desktop ? 6 : 12}>
              <ImageList rowHeight={320} cols={2}>
                {imageListData?.map((item) => (
                  <ImageListItem key={item.img} cols={item.cols || 1}>
                    <img src={item.img} alt={item.title} />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          </Grid>
        </section>
      </Layout>
    </>
  );
};

export default About;
